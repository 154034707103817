import { Note } from '../components/notes/types';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

export async function shareContent(content: string, title: string = 'Shared from Tutor Buddy') {
  try {
    if (navigator.share) {
      await navigator.share({
        title,
        text: content,
      });
      return true;
    }
    
    await navigator.clipboard.writeText(content);
    return true;
  } catch (error) {
    if ((error as Error).name === 'AbortError') {
      return false;
    }
    return false;
  }
}

export function copyToClipboard(text: string): Promise<boolean> {
  return navigator.clipboard.writeText(text)
    .then(() => true)
    .catch(() => false);
}

export function formatNotesForExport(notes: Note[]): string {
  return notes.map(note => {
    const date = new Date(note.timestamp).toLocaleString();
    const tags = note.tags.join(', ');
    const reminder = note.reminder ? `\nReminder: ${new Date(note.reminder).toLocaleString()}` : '';
    return `[${date}] ${tags ? `[${tags}]` : ''}\n${note.content}${reminder}\n\n`;
  }).join('---\n\n');
}

export function formatChatForExport(messages: { text: string; isAI: boolean }[]): string {
  return messages.map(msg => 
    `${msg.isAI ? 'AI Tutor' : 'You'}: ${msg.text}\n`
  ).join('\n');
}

export async function exportToPDF(elementId: string, filename: string) {
  const element = document.getElementById(elementId);
  if (!element) return;

  try {
    const canvas = await html2canvas(element, {
      scale: 2,
      backgroundColor: '#1a1a1a',
      logging: false,
      useCORS: true,
      allowTaint: true
    });

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    const imgData = canvas.toDataURL('image/png');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(filename);
  } catch (error) {
    throw new Error('Failed to generate PDF');
  }
}

export function printContent(content: string | HTMLElement) {
  const printWindow = window.open('', '_blank');
  if (!printWindow) return;

  const styles = `
    <style>
      body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        color: #1a1a1a;
        padding: 20px;
        background: white;
      }
      .content {
        margin-bottom: 20px;
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background: white;
        white-space: pre-wrap;
      }
      .header {
        text-align: center;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 2px solid #ddd;
      }
      .footer {
        text-align: center;
        margin-top: 20px;
        padding-top: 10px;
        border-top: 2px solid #ddd;
        font-size: 12px;
        color: #666;
      }
      @media print {
        body {
          padding: 0;
        }
        .content {
          break-inside: avoid;
          page-break-inside: avoid;
        }
      }
    </style>
  `;

  const contentHtml = typeof content === 'string' 
    ? content 
    : content.innerHTML;

  printWindow.document.write(`
    <!DOCTYPE html>
    <html>
      <head>
        <title>Print - Tutor Buddy</title>
        ${styles}
      </head>
      <body>
        <div class="header">
          <h1>Tutor Buddy</h1>
          <p>Generated on ${new Date().toLocaleString()}</p>
        </div>
        <div class="content">
          ${contentHtml}
        </div>
        <div class="footer">
          <p>Generated by Tutor Buddy - Your AI Study Companion</p>
        </div>
      </body>
    </html>
  `);

  printWindow.document.close();
  
  // Wait for content to load before printing
  setTimeout(() => {
    printWindow.print();
    // Close the window after printing (with a delay to ensure print dialog shows)
    setTimeout(() => {
      printWindow.close();
    }, 250);
  }, 250);
}