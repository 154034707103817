import { db } from '../firebase';
import { doc, collection, query, orderBy, getDocs, setDoc, deleteDoc, updateDoc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { Note } from '../../components/notes/types';

export const saveNote = async (userId: string, note: Note) => {
  try {
    const noteRef = doc(db, `users/${userId}/notes/${note.id}`);
    await setDoc(noteRef, {
      ...note,
      updatedAt: serverTimestamp()
    });
    return true;
  } catch (error) {
    console.error('Error saving note:', error);
    return false;
  }
};

export const getUserNotes = async (userId: string) => {
  try {
    const notesRef = collection(db, `users/${userId}/notes`);
    const q = query(notesRef, orderBy('timestamp', 'desc'));
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      timestamp: doc.data().timestamp?.toDate().getTime() || Date.now(),
      reminder: doc.data().reminder?.toDate().toISOString() || null
    }));
  } catch (error) {
    console.error('Error getting notes:', error);
    return [];
  }
};

export const deleteNote = async (userId: string, noteId: string) => {
  try {
    await deleteDoc(doc(db, `users/${userId}/notes/${noteId}`));
    return true;
  } catch (error) {
    console.error('Error deleting note:', error);
    return false;
  }
};

export const updateNoteReminder = async (userId: string, noteId: string, reminderDate: Date) => {
  try {
    const noteRef = doc(db, `users/${userId}/notes/${noteId}`);
    await updateDoc(noteRef, {
      reminder: Timestamp.fromDate(reminderDate),
      reminderShown: false,
      updatedAt: serverTimestamp()
    });
    return true;
  } catch (error) {
    console.error('Error updating note reminder:', error);
    return false;
  }
};