import { db, auth } from '../firebase';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

export const createUserDocument = async (userId: string, data: any) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      // For new users, set initial role and notification preferences
      await setDoc(userRef, {
        ...data,
        role: 'user',
        notificationsEnabled: true,
        notificationPreference: 'sms',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
    } else {
      // For existing users, just update the provided data
      await setDoc(userRef, {
        ...data,
        updatedAt: serverTimestamp()
      }, { merge: true });
    }
  } catch (error) {
    console.error('Error creating/updating user document:', error);
    throw error;
  }
};

export const isUserAdmin = async (userId: string): Promise<boolean> => {
  try {
    if (!userId) return false;
    
    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.exists() && userDoc.data()?.role === 'admin';
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

export const signOut = async () => {
  const user = auth.currentUser;
  if (user?.email) {
    await stopSessionMonitoring(user.email);
  }
  return auth.signOut();
};

// Session Management
export const startSessionMonitoring = async (email: string) => {
  if (!email) return;
  
  try {
    const sessionRef = doc(db, 'sessions', email);
    await setDoc(sessionRef, {
      lastActive: serverTimestamp(),
      email,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error starting session monitoring:', error);
  }
};

export const stopSessionMonitoring = async (email: string) => {
  if (!email) return;
  
  try {
    const sessionRef = doc(db, 'sessions', email);
    await deleteDoc(sessionRef);
  } catch (error) {
    console.error('Error stopping session monitoring:', error);
  }
};

export const hasActiveSession = async (email: string): Promise<boolean> => {
  if (!email) return false;
  
  try {
    const sessionRef = doc(db, 'sessions', email);
    const sessionSnap = await getDoc(sessionRef);
    
    if (!sessionSnap.exists()) return false;
    
    const data = sessionSnap.data();
    const lastActive = data.lastActive?.toDate() || new Date(0);
    const fiveMinutesAgo = new Date(Date.now() - 5 * 60 * 1000);
    
    return lastActive > fiveMinutesAgo;
  } catch (error) {
    console.error('Error checking session:', error);
    return false;
  }
};