import { db } from '../firebase';
import { doc, collection, query, orderBy, getDocs, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { StudyPlan, StudyPreferences } from '../../components/planner/types';

export interface StudyPlanProject {
  id: string;
  plan: StudyPlan;
  preferences: StudyPreferences;
  timestamp: number;
}

export const saveStudyPlan = async (userId: string, project: StudyPlanProject): Promise<string> => {
  try {
    const planRef = doc(db, `users/${userId}/studyPlans/${project.id}`);
    await setDoc(planRef, {
      ...project,
      updatedAt: serverTimestamp()
    });
    return project.id;
  } catch (error) {
    console.error('Error saving study plan:', error);
    throw error;
  }
};

export const getStudyPlans = async (userId: string): Promise<StudyPlanProject[]> => {
  try {
    const plansRef = collection(db, `users/${userId}/studyPlans`);
    const q = query(plansRef, orderBy('timestamp', 'desc'));
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as StudyPlanProject[];
  } catch (error) {
    console.error('Error getting study plans:', error);
    return [];
  }
};

export const deleteStudyPlan = async (userId: string, planId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, `users/${userId}/studyPlans/${planId}`));
  } catch (error) {
    console.error('Error deleting study plan:', error);
    throw error;
  }
};