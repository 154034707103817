import { db } from '../firebase';
import { doc, collection, query, orderBy, getDocs, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { DataAnalysis } from '../../components/analysis/types';

export interface AnalysisProject {
  id: string;
  data: any[];
  columns: string[];
  analysis: DataAnalysis;
  timestamp: number;
}

export const saveAnalysisProject = async (userId: string, project: AnalysisProject): Promise<string> => {
  try {
    const projectRef = doc(db, `users/${userId}/analysis/${project.id}`);
    await setDoc(projectRef, {
      ...project,
      updatedAt: serverTimestamp()
    });
    return project.id;
  } catch (error) {
    console.error('Error saving analysis project:', error);
    throw error;
  }
};

export const getAnalysisProjects = async (userId: string): Promise<AnalysisProject[]> => {
  try {
    const projectsRef = collection(db, `users/${userId}/analysis`);
    const q = query(projectsRef, orderBy('timestamp', 'desc'));
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as AnalysisProject[];
  } catch (error) {
    console.error('Error getting analysis projects:', error);
    return [];
  }
};

export const deleteAnalysisProject = async (userId: string, projectId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, `users/${userId}/analysis/${projectId}`));
  } catch (error) {
    console.error('Error deleting analysis project:', error);
    throw error;
  }
};