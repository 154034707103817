import { useState, useCallback, useRef, useEffect } from 'react';

interface SpeechOptions {
  rate?: number;
  pitch?: number;
  volume?: number;
  voice?: SpeechSynthesisVoice;
}

export function useSpeechSynthesis() {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const utteranceRef = useRef<SpeechSynthesisUtterance | null>(null);
  const voicesLoadedRef = useRef(false);
  const selectedVoiceRef = useRef<SpeechSynthesisVoice | null>(null);
  const textChunksRef = useRef<string[]>([]);
  const currentChunkRef = useRef(0);
  const isStoppingRef = useRef(false);

  useEffect(() => {
    const loadVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      // Prioritize English voices
      const englishVoice = voices.find(voice => 
        voice.lang.includes('en') && 
        !voice.name.toLowerCase().includes('zira') // Avoid Zira due to interruption issues
      );

      if (englishVoice) {
        selectedVoiceRef.current = englishVoice;
      }
      voicesLoadedRef.current = true;
    };

    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;
    
    return () => {
      window.speechSynthesis.onvoiceschanged = null;
    };
  }, []);

  // Split text into smaller chunks for better stability
  const splitTextIntoChunks = (text: string): string[] => {
    // Split on sentence boundaries and punctuation
    const sentences = text.match(/[^.!?]+[.!?]+|\s+/g) || [text];
    const chunks: string[] = [];
    let currentChunk = '';

    for (const sentence of sentences) {
      // Use smaller chunks (50 chars) for better stability
      if (currentChunk.length + sentence.length < 50) {
        currentChunk += sentence;
      } else {
        if (currentChunk) chunks.push(currentChunk.trim());
        currentChunk = sentence;
      }
    }
    if (currentChunk) chunks.push(currentChunk.trim());
    return chunks.filter(chunk => chunk.length > 0);
  };

  const speakNextChunk = useCallback(() => {
    if (isStoppingRef.current || currentChunkRef.current >= textChunksRef.current.length) {
      setIsSpeaking(false);
      currentChunkRef.current = 0;
      textChunksRef.current = [];
      isStoppingRef.current = false;
      return;
    }

    const chunk = textChunksRef.current[currentChunkRef.current];
    const utterance = new SpeechSynthesisUtterance(chunk);
    utteranceRef.current = utterance;

    // Optimized speech settings
    utterance.rate = 0.9;
    utterance.pitch = 1.0;
    utterance.volume = 1;

    if (selectedVoiceRef.current) {
      utterance.voice = selectedVoiceRef.current;
    }

    utterance.onstart = () => {
      setIsSpeaking(true);
    };

    utterance.onend = () => {
      if (isStoppingRef.current) {
        setIsSpeaking(false);
        currentChunkRef.current = 0;
        textChunksRef.current = [];
        isStoppingRef.current = false;
        return;
      }

      currentChunkRef.current++;
      setTimeout(() => {
        speakNextChunk();
      }, 250);
    };

    utterance.onerror = (event) => {
      console.error('Speech synthesis error:', event);
      setIsSpeaking(false);
      currentChunkRef.current = 0;
      textChunksRef.current = [];
      isStoppingRef.current = false;
    };

    // Handle audio context resumption
    const resumeAudioContext = () => {
      if (window.speechSynthesis.paused) {
        window.speechSynthesis.resume();
      }
    };

    document.addEventListener('visibilitychange', resumeAudioContext);
    window.addEventListener('focus', resumeAudioContext);

    window.speechSynthesis.speak(utterance);

    return () => {
      document.removeEventListener('visibilitychange', resumeAudioContext);
      window.removeEventListener('focus', resumeAudioContext);
    };
  }, []);

  const speak = useCallback((text: string, options: SpeechOptions = {}) => {
    if (!text || isSpeaking) return;

    // Cancel any ongoing speech
    stop();

    // Split text into smaller chunks
    textChunksRef.current = splitTextIntoChunks(text);
    currentChunkRef.current = 0;
    isStoppingRef.current = false;

    const startSpeaking = () => {
      if (window.speechSynthesis.paused) {
        window.speechSynthesis.resume();
      }
      speakNextChunk();
    };

    if (voicesLoadedRef.current) {
      startSpeaking();
    } else {
      const maxAttempts = 10;
      let attempts = 0;
      
      const trySpeak = () => {
        if (window.speechSynthesis.getVoices().length > 0) {
          startSpeaking();
        } else if (attempts < maxAttempts) {
          attempts++;
          setTimeout(trySpeak, 100);
        }
      };
      
      trySpeak();
    }
  }, [isSpeaking, speakNextChunk]);

  const stop = useCallback(() => {
    isStoppingRef.current = true;
    window.speechSynthesis.cancel();
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      currentChunkRef.current = 0;
      textChunksRef.current = [];
      isStoppingRef.current = false;
    };
  }, []);

  return { speak, stop, isSpeaking };
}